var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",attrs:{"id":"right0"}},[_c('Navigation',{attrs:{"navigations":_vm.navigation},on:{"ievent":_vm.ievent}}),_c('div',{staticClass:"shuffling"},[_c('div',{staticClass:"shuffling_p"},[_c('div',{staticClass:"shuffling_ps"},[_c('h2',{class:_vm.styleshow == true
            ? 'animate__animated animate__backInRight'
            : 'animate__animated animate__backInDown'},[_vm._v(" "+_vm._s(_vm.tileir)+" ")]),_c('div',{staticClass:"Fu_can"},[_c('div',{class:_vm.styleshow == true
              ? 'animate__animated animate__backOutRight'
              : 'animate__animated animate__backInRight'},[_c('div',{staticClass:"line"})])]),_c('div',{staticClass:"shuf_pc"},[_c('div',{staticClass:"p1"},[_c('p',{class:_vm.styleshow == true
                ? 'animate__animated animate__backOutLeft'
                : 'animate__animated animate__backInLeft'},[_vm._v(" "+_vm._s(_vm.center)+" ")])]),_c('div',{staticClass:"p2"},[_c('p',{class:_vm.styleshow == true
                ? 'animate__animated animate__backOutRight'
                : 'animate__animated animate__backInRight'},[_vm._v(" "+_vm._s(_vm.center2)+" ")])]),_c('div',{staticClass:"p3"},[_c('p',{class:_vm.styleshow == true
                ? 'animate__animated animate__backOutLeft'
                : 'animate__animated animate__backInLeft'},[_vm._v(" "+_vm._s(_vm.center3)+" ")])])]),_c('div',{staticClass:"shuf_iphone"},[_c('div',{staticClass:"p1"},[_c('p',{class:_vm.styleshow == true
                ? 'animate__animated animate__backOutLeft'
                : 'animate__animated animate__backInLeft'},[_vm._v(" "+_vm._s(_vm.center_iphone1)+" ")])]),_c('div',{staticClass:"p2"},[_c('p',{class:_vm.styleshow == true
                ? 'animate__animated animate__backOutRight'
                : 'animate__animated animate__backInRight'},[_vm._v(" "+_vm._s(_vm.center_iphone3)+" ")])]),_c('div',{staticClass:"p3"},[_c('p',{class:_vm.styleshow == true
                ? 'animate__animated animate__backOutLeft'
                : 'animate__animated animate__backInLeft'},[_vm._v(" "+_vm._s(_vm.center_iphone2)+" ")])]),_c('div',{staticClass:"p3"},[_c('p',{class:_vm.styleshow == true
                ? 'animate__animated animate__backOutLeft'
                : 'animate__animated animate__backInLeft'},[_vm._v(" "+_vm._s(_vm.center_iphone4)+" ")])]),_c('div',{staticClass:"p3"},[_c('p',{class:_vm.styleshow == true
                ? 'animate__animated animate__backOutLeft'
                : 'animate__animated animate__backInLeft'},[_vm._v(" "+_vm._s(_vm.center_iphone5)+" ")])])])])]),_c('img',{staticClass:"images_pc",attrs:{"src":_vm.images,"alt":""}}),_c('img',{staticClass:"images_phone",attrs:{"src":_vm.images_phone,"alt":""}})]),_c('Chassis',{attrs:{"Software":_vm.Software}}),_c('div',{staticClass:"right2",attrs:{"id":"right1"}}),_c('div'),_c('div',{staticClass:"chassis"},[_c('span',{staticClass:"chassis_span"},[_vm._v(_vm._s(_vm.By_wire))]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"chassis_ps"},[_vm._v(_vm._s(_vm.By_wire1))]),_c('p',{staticClass:"chassis_ps"},[_vm._v(" "+_vm._s(_vm.By_wire2)+" ")]),_c('Carousel')],1),_c('div',{staticClass:"right2",attrs:{"id":"right2"}}),_c('div'),_c('div',{staticClass:"chassis"},[_c('span',{staticClass:"chassis_span"},[_vm._v(_vm._s(_vm.controlled_by_wire))]),_c('div',{staticClass:"line"}),_c('Wirecontrolcore',{attrs:{"corelist":_vm.corelist}})],1),_c('div',{staticClass:"tabbar"},[_c('div',{attrs:{"id":"right3"}}),_c('div'),_c('div',{staticClass:"AcMotion"},[_c('div',{staticClass:"logo1"},[_vm._v(_vm._s(_vm.Contact4))]),_c('div',{staticClass:"logo2"},[_vm._v(_vm._s(_vm.Contact5))]),_c('div',{staticClass:"Contact"},[_c('div',[_vm._v(_vm._s(_vm.Contact1))]),_c('div',[_vm._v(_vm._s(_vm.Contact2))]),_c('div',[_vm._v(_vm._s(_vm.Contact3))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }