<template>
  <div class="home" id="right0">
    <Navigation :navigations="navigation" @ievent="ievent" />
    <div class="shuffling">
      <div class="shuffling_p">
        <div class="shuffling_ps">
          <h2 :class="
            styleshow == true
              ? 'animate__animated animate__backInRight'
              : 'animate__animated animate__backInDown'
          ">
            {{ tileir }}
          </h2>
          <div class="Fu_can">
            <div :class="
              styleshow == true
                ? 'animate__animated animate__backOutRight'
                : 'animate__animated animate__backInRight'
            ">
              <div class="line"></div>
            </div>
          </div>
          <div class="shuf_pc">
            <div class="p1">
              <p :class="
                styleshow == true
                  ? 'animate__animated animate__backOutLeft'
                  : 'animate__animated animate__backInLeft'
              ">
                {{ center }}
              </p>
            </div>
            <div class="p2">
              <p :class="
                styleshow == true
                  ? 'animate__animated animate__backOutRight'
                  : 'animate__animated animate__backInRight'
              ">
                {{ center2 }}
              </p>
            </div>
            <div class="p3">
              <p :class="
                styleshow == true
                  ? 'animate__animated animate__backOutLeft'
                  : 'animate__animated animate__backInLeft'
              ">
                {{ center3 }}
              </p>
            </div>
          </div>
          <div class="shuf_iphone">
            <div class="p1">
              <p :class="
                styleshow == true
                  ? 'animate__animated animate__backOutLeft'
                  : 'animate__animated animate__backInLeft'
              ">
                {{ center_iphone1 }}
              </p>
            </div>
            <div class="p2">
              <p :class="
                styleshow == true
                  ? 'animate__animated animate__backOutRight'
                  : 'animate__animated animate__backInRight'
              ">
                {{ center_iphone3 }}
              </p>
            </div>
            <div class="p3">
              <p :class="
                styleshow == true
                  ? 'animate__animated animate__backOutLeft'
                  : 'animate__animated animate__backInLeft'
              ">
                {{ center_iphone2 }}
              </p>
            </div>
            <div class="p3">
              <p :class="
                styleshow == true
                  ? 'animate__animated animate__backOutLeft'
                  : 'animate__animated animate__backInLeft'
              ">
                {{ center_iphone4 }}
              </p>
            </div>
            <div class="p3">
              <p :class="
                styleshow == true
                  ? 'animate__animated animate__backOutLeft'
                  : 'animate__animated animate__backInLeft'
              ">
                {{ center_iphone5 }}
              </p>
            </div>
          </div>
        </div>

      </div>
      <img :src="images" class="images_pc" alt="" />
      <img :src="images_phone" class="images_phone" alt="" />
    </div>
    <Chassis :Software="Software"></Chassis>
    <div id="right1" class="right2"></div>
    <div></div>
    <div class="chassis">
      <span class="chassis_span">{{ By_wire }}</span>
      <div class="line"></div>
      <p class="chassis_ps">{{ By_wire1 }}</p>
      <p class="chassis_ps">
        {{ By_wire2 }}
      </p>
      <Carousel></Carousel>
    </div>
    <div id="right2" class="right2"></div>
    <div></div>
    <div class="chassis">
      <span class="chassis_span">{{ controlled_by_wire }}</span>
      <div class="line"></div>
      <Wirecontrolcore :corelist="corelist"></Wirecontrolcore>
    </div>
    <div class="tabbar">
      <!-- <img src="../assets/tabbar.png" class="tabbar_img" alt=""> -->
      <div id="right3"></div>
      <div></div>
      <div class="AcMotion">
        <div class="logo1">{{ Contact4 }}</div>
        <div class="logo2">{{ Contact5 }}</div>
        <div class="Contact">
          <div>{{ Contact1 }}</div>
          <div>{{ Contact2 }}</div>
          <div>{{ Contact3 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OrderService } from "../api/home";
import Navigation from "@/components/Navigation .vue";
import Chassis from "@/components/chassis.vue";
import Carousel from "@/components/Carousel.vue";
import Wirecontrolcore from "@/components/Wirecontrolcore.vue";

export default {
  name: "HomeView",
  components: {
    Navigation,
    Chassis,
    Carousel,
    Wirecontrolcore,
  },
  data() {
    return {
      navigation: [

      ],
      Software: [
        {
          name: "",
          center1: ",",
          center2: " ",
          img: "",
          img2: "",
        },
        {
          name: " ",
          center1: "",
          center2: "",
          img: "",
          img2: "",
        },
      ],
      center: "",
      center2: "",
      center3: "",
      center_iphone1: "",
      center_iphone2: "",
      center_iphone3: "",
      center_iphone4: "",
      center_iphone5: "",
      pageID: null,
      styleshow: false,
      tileir: "",
      images: "",
      images_phone: "",
      By_wire: '',
      By_wire1: '',
      By_wire2: '',
      Contact1: '',
      Contact2: '',
      Contact3: '',
      Contact4: '',
      Contact5: '',
      controlled_by_wire: '',

      corelist: [

      ],
    };
  },
  mounted() {
    this.gethomelist();
    this.getnavlist();
    window.addEventListener("scroll", this.ficedActiveBtn);
  },
  methods: {
    ievent(pageid) {
      this.pageID = pageid;
      if (this.pageID == 0) {
        document.querySelector("#right0").scrollIntoView(
          {
            top: "#right0",
            behavior: "smooth",
          },
          true
        );
      } else if (this.pageID == 1) {
        document.querySelector("#right1").scrollIntoView(
          {
            top: "#right1",
            behavior: "smooth",
          },
          true
        );
      } else if (this.pageID == 2) {
        document.querySelector("#right2").scrollIntoView(
          {
            top: "#right2",
            behavior: "smooth",
          },
          true
        );
      } else if (this.pageID == 3) {
        document.querySelector("#right3").scrollIntoView(
          {
            top: "#right3",
            behavior: "smooth",
          },
          true
        );
      } else if (this.pageID == 4) {
        // document.querySelector("#right4").scrollIntoView(
        //   {
        //     top: "#right4",
        //     behavior: "smooth",
        //   },
        //   true
        // );
      }
    },
    ficedActiveBtn() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollTop > 100 ? (this.styleshow = true) : (this.styleshow = false);
    },
    gethomelist() {
      OrderService.gethome().then((res) => {
        this.tileir = res.data[0].title;
        this.center = res.data[0].content1;
        this.center2 = res.data[0].content2;
        this.center3 = res.data[0].content3;
        this.center_iphone1 = res.data[1].content1;
        this.center_iphone2 = res.data[1].content2;
        this.center_iphone3 = res.data[1].content3;
        this.center_iphone4 = res.data[1].content4;
        this.center_iphone5 = res.data[1].content5;
        this.images = res.data[0].imgurl;
        this.images_phone = res.data[1].imgurl;
        this.Contact1 = res.data[14].content1
        this.Contact2 = res.data[14].content2
        this.Contact3 = res.data[14].content3
        this.Contact4 = res.data[14].content4
        this.Contact5 = res.data[14].content5
        this.By_wire = res.data[4].title
        this.By_wire1 = res.data[4].content1
        this.By_wire2 = res.data[4].content2
        this.controlled_by_wire = res.data[7].title
        this.Software[0].name = res.data[2].title
        this.Software[0].center1 = res.data[2].content1
        this.Software[0].center2 = res.data[2].content2
        this.Software[0].img = res.data[2].imgurl
        this.Software[1].name = res.data[3].title
        this.Software[1].center1 = res.data[3].content1
        this.Software[1].center2 = res.data[3].content2
        this.Software[1].img2 = res.data[3].imgurl
        this.corelist.push(res.data[8])
        this.corelist.push(res.data[9])
        this.corelist.push(res.data[10])
        this.corelist.push(res.data[11])
        this.corelist.push(res.data[12])
        this.corelist.push(res.data[13])
        this.corelist.push(res.data[15])
        this.corelist.push(res.data[16])
        this.corelist.push(res.data[17])
        this.corelist.push(res.data[18])
        this.corelist.push(res.data[19])
        this.corelist.push(res.data[20])
      });
    },
    getnavlist() {
      OrderService.gettabar().then((res) => {
        this.navigation = res.data

      })
    },


  },
};
</script>
<style lang="scss" scoped>
.shuffling {
  position: relative;

  .images_pc {
    width: 100%;
    // height: 400px;
  }

  .images_phone {
    display: none;
    width: 100%;
  }

  .Fu_can {
    margin-top: 31px;
    margin-bottom: 56px;
    // width: 100%;
    // display: flex;
    // justify-content: center;
  }

  .shuffling_p {
    width: 100%;
    margin-top: 5%;
    position: absolute;
    display: flex;
    justify-content: center;

    .shuffling_ps {
      width: 50%;
    }
  }

  .line {
    width: 167px;
    height: 3px;
    background: #ffffff;
    margin: 0 auto;
  }

  h2 {
    text-align: center;
    margin-top: 50px;
    font-size: 80px;
    font-weight: bold;
    color: #fff;
  }

  p {
    color: #fff;
    text-align: center;
    margin: 3px;
  }
}

.chassis {
  margin-top: 120px;
  text-align: center;

  span {
    color: #171717;
    font-size: 25px;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  }

  p {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #171717;
    line-height: 27px;
    opacity: 0.7;
    margin: 5px;
  }
}

.line {
  margin-top: 5px !important;
  width: 49px;
  height: 4px;
  background: #333333;
  margin: 0 auto;
}

.tabbar {
  width: 100%;
  height: 668px;
  background-size: 100%;
  background-image: url("../assets/image/tabbar.png");
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  .tabbar_img {
    width: 100%;
  }

  .AcMotion {
    position: absolute;
    width: 600px;
    // height: 204px;
    background: #041f33c2;
    color: #ffffff;
    text-align: center;
    left: 50%;
  }

  .Contact {
    margin: 20px 60px 60px 60px;
    font-size: 20px;

    div {
      margin: 5px;
    }
  }

  .logo1 {
    padding-top: 20px;
    font-size: 45px;
    margin: 0 20px;
    // font-weight: bold;
  }

  .logo2 {
    margin: 0 20px;
    font-size: 45px;
    // font-weight: bold;
  }
}

.shuf_iphone {
  display: none;
}

.shuf_pc {
  display: block;
}
</style>
