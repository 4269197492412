<template>
  <div class="Wirecontrolcore">
    <div class="Wirecontrolcore_carousel">
      <el-carousel indicator-position="none" :interval="8000" @change="jumps" :initial-index="0" ref="remarkCarusel"
        height="350px">
        <el-carousel-item v-for="(items, index) in controlledmotion" :key="index">
          <div class="roof">
            <div style="width: 60%;">
              <div>
                <h4>{{ items.name }}</h4>
                <div class="liness"></div>
              </div>
              <div class="information">
                <span>{{ items.Linecontrolled.title }}</span>
                <p style="width: 90%">{{ items.Linecontrolled.content1 }}</p>
                <p style="width: 90%">{{ items.Linecontrolled.content2 }}</p>
                <p style="width: 80%">{{ items.Linecontrolled.content3 }}</p>
                <div>
                  <span>{{ items.Performances.title }}</span>
                </div>
                <div class="bottom_information" v-if="items.id == 1">
                  <div>
                    <p>{{ items.Performances.content1 }}</p>
                    <p>{{ items.Performances.content2 }}</p>
                    <p>{{ items.Performances.content3 }}</p>
                  </div>
                  <div style="margin-left: 5%">
                    <p>{{ items.Performances.content4 }}</p>
                    <p>{{ items.Performances.content5 }}</p>
                    <p>{{ items.Performances.content6 }}</p>
                  </div>
                </div>

                <div class="bottom_information" v-if="items.id == 2">
                  <div>
                    <p>{{ items.Performances.content1 }}</p>

                  </div>
                  <div style="margin-left: 5%">

                  </div>
                </div>
                <div v-if="items.id == 3">
                  <p>{{ items.Performances.content1 }}</p>
                  <p>{{ items.Performances.content2 }}</p>
                  <p>{{ items.Performances.content3 }}</p>
                  <p>{{ items.Performances.content4 }}</p>
                </div>



                <span>{{ items.actuator.title }}</span>
                <div v-if="items.id == 1">
                  <p>{{ items.actuator.content1 }}</p>
                  <p>{{ items.actuator.content2 }}</p>
                  <p>{{ items.actuator.content3 }}</p>
                  <p>{{ items.actuator.content4 }}</p>
                  <p>{{ items.actuator.content5 }}</p>
                </div>
                <div class="bottom_information" v-if="items.id == 2">
                  <div>
                    <p>{{ items.actuator.content1 }}</p>
                    <p>{{ items.actuator.content2 }}</p>
                    <p>{{ items.actuator.content3 }}</p>
                    <p>{{ items.actuator.content4 }}</p>
                    <p>{{ items.actuator.content5 }}</p>


                  </div>
                  <div style="margin-left: 5%">
                    <p>{{ items.actuator.content6 }}</p>
                    <p>{{ items.actuator.content7 }}</p>
                    <p>{{ items.actuator.content8 }}</p>
                    <p>{{ items.actuator.content9 }}</p>
                    <p>{{ items.actuator.content10 }}</p>
                  </div>
                </div>

                <div v-if="items.id == 3">
                  <p>{{ items.actuator.content1 }}</p>
                  <p>{{ items.actuator.content2 }}</p>
                  <p>{{ items.actuator.content3 }}</p>
                  <p>{{ items.actuator.content4 }}</p>

                </div>
              </div>
            </div>
            <div class="img_describe">
              <img :src="items.img" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="Wirecontrolcore_setting">
      <ul v-for="(item, index) in controlledmotion" :key="index" @dblclick="dbclick" @click="jump(item.id)"
        @mouseover="overItem(index)" @mouseleave="removeActive(index)">
        <div :class="{ mask: item.id == itemindex }">
          <div class="Triangle">
            <div class="Triangles"></div>
          </div>
          <div>
            <span v-show="item.id == itemindex">{{ item.name }}</span>
            <div style=" display: flex;justify-content: center;align-items: center;">
              <div class="line"></div>
            </div>
          </div>
        </div>
        <li>
          <div class="following">
            <div>
              <div class="images">
                <img :src="item.img" :class="styleshow == index ? 'image_change' : ''" alt="" />
              </div>
              <div class="title">
                <span v-show="item.id != itemindex">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="Wirecontrolcore_phone" @touchstart="start($event)" @touchmove="move($event)">
      <el-carousel ref="nop" arrow="never" :interval="8000" :autoplay="autoplay" trigger="click" height="460px">
        <div>
          <el-carousel-item v-for="(itemxs, indexsx) in controlledmotion" :key="indexsx">
            <div class="roof_phone">
              <div class="">
                <h3 class="small">{{ itemxs.name }}</h3>
                <div class="liness"></div>
                <div class="information_phone_img">
                  <img :src="itemxs.imgurl" alt="">
                </div>
              </div>
              <div class="information_phone">
                <div class="border">
                  <span>{{ itemxs.Linecontrolled.title }}</span>
                </div>
                <p>{{ itemxs.Linecontrolled.content1 }}</p>
                <p>{{ itemxs.Linecontrolled.content2 }}</p>
                <p>{{ itemxs.Linecontrolled.content3 }}</p>
                <div class="border">
                  <span>{{ itemxs.Performances.title }}</span>
                </div>
                <div v-if="itemxs.id == 3">
                  <p>{{ itemxs.Performances.content1 }}</p>
                  <p>{{ itemxs.Performances.content2 }}</p>
                  <p>{{ itemxs.Performances.content3 }}</p>
                  <p>{{ itemxs.Performances.content4 }}</p>
                </div>
                <div class="bottom_information_phone" v-if="itemxs.id == 1">
                  <div>
                    <p>{{ itemxs.Performances.content1 }}</p>
                    <p>{{ itemxs.Performances.content2 }}</p>
                    <p>{{ itemxs.Performances.content3 }}</p>
                  </div>
                  <div style="margin-left: 5%">
                    <p>{{ itemxs.Performances.content4 }}</p>
                    <p>{{ itemxs.Performances.content5 }}</p>
                    <p>{{ itemxs.Performances.content6 }}</p>
                  </div>
                </div>
                <div class="bottom_information_phone" v-if="itemxs.id == 2">
                  <div>
                    <p>{{ itemxs.Performances.content1 }}</p>

                  </div>
                  <div style="margin-left: 5%">

                  </div>
                </div>
                <div class="border">
                  <span>{{ itemxs.actuator.title }}</span>
                </div>
                <div v-if="itemxs.id == 1">
                  <p>{{ itemxs.actuator.content1 }}</p>
                  <p>{{ itemxs.actuator.content2 }}</p>
                  <p>{{ itemxs.actuator.content3 }}</p>
                  <p>{{ itemxs.actuator.content4 }}</p>
                  <p>{{ itemxs.actuator.content5 }}</p>
                </div>
                <div class="bottom_information_phone" v-if="itemxs.id == 2">
                  <div>
                    <p>{{ itemxs.actuator.content1 }}</p>
                    <p>{{ itemxs.actuator.content2 }}</p>
                    <p>{{ itemxs.actuator.content3 }}</p>
                    <p>{{ itemxs.actuator.content4 }}</p>
                    <p>{{ itemxs.actuator.content5 }}</p>
                  </div>
                  <div style="margin-left: 5%">
                    <p>{{ itemxs.actuator.content6 }}</p>
                    <p>{{ itemxs.actuator.content7 }}</p>
                    <p>{{ itemxs.actuator.content8 }}</p>
                    <p>{{ itemxs.actuator.content9 }}</p>
                    <p>{{ itemxs.actuator.content10 }}</p>
                  </div>
                </div>
                <div v-if="itemxs.id == 3">
                  <p>{{ itemxs.actuator.content1 }}</p>
                  <p>{{ itemxs.actuator.content2 }}</p>
                  <p>{{ itemxs.actuator.content3 }}</p>
                  <p>{{ itemxs.actuator.content4 }}</p>
                </div>
              </div>

            </div>

          </el-carousel-item>
        </div>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: ['corelist'],
  data() {
    return {
      controlledmotion: [
        {
          name: "",
          id: 1,
          img: '',
          imgurl: '',
          Linecontrolled: { title: '', content1: '', content2: '', content3: '' },
          Performances: { title: '', content1: '', content2: '', content3: '' },
          actuator: { title: '', content1: '', content2: '', content3: '' },

        },
        {
          name: "线控转向 SWB ",
          id: 2,
          img: '',
          imgurl: '',
          Linecontrolled: { title: '', content1: '', content2: '', content3: '' },
          Performances: { title: '', content1: '', content2: '', content3: '' },
          actuator: { title: '', content1: '', content2: '', content3: '' },
        },
        {
          name: "底盘域控制器DCU",
          id: 3,
          img: '',
          imgurl: '',
          Linecontrolled: { title: '', content1: '', content2: '', content3: '' },
          Performances: { title: '', content1: '', content2: '', content3: '', content4: '' },
          actuator: { title: '', content1: '', content2: '', content3: '' },
        },
      ],
      itemindex: 1,
      i: 0,
      styleshow: 0,
      autoplay: true,
      startX: '',
      startY: '',
      moveY: '',
      moveX: '',
      startTime: '',
      time: '',
    };
  },

  methods: {
    jump(e) {
      let that = this
      // 开启延时器，200ms的间隔用来区分单击和双击，解决双击时执行两次单击事件
      clearTimeout(that.time)
      that.time = setTimeout(() => {
        // console.log('单击')
        that.itemindex = e - 1;
        that.$refs.remarkCarusel.setActiveItem(this.itemindex);
      }, 200)
    },
    dbclick() {
      clearTimeout(this.time)
      // console.log('双击')
      // 双击事件 不写不执行，禁用
    },
    overItem(e) {
      this.styleshow = e;
    },
    removeActive(e) { },
    jumps(index) {
      this.itemindex = index + 1;
    },
    start(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    move(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      var nowtime = new Date().getTime();
      if (this.startTime == undefined || nowtime > this.startTime) {
        if (this.startX - this.moveX < -50 && this.moveX) { // 右滑触发
          this.prev();
          return false;
        } else if (this.startX - this.moveX > 50 && this.moveX) { //左滑触发
          this.next();
          return false;
        }
      }
    },
    prev() {
      this.$refs.nop.prev();
      this.startTime = new Date().getTime() + 500;

    },
    next() {
      this.$refs.nop.next();
      this.startTime = new Date().getTime() + 500;

    },
  },
  watch: {
    corelist(val) {
      this.controlledmotion[0].name = val[0].title
      this.controlledmotion[0].img = val[0].imgurl
      this.controlledmotion[0].imgurl = val[0].imgurlA
      this.controlledmotion[0].Linecontrolled = val[1]
      this.controlledmotion[0].Performances = val[2]
      this.controlledmotion[0].actuator = val[3]
      this.controlledmotion[1].name = val[4].title
      this.controlledmotion[1].img = val[4].imgurl
      this.controlledmotion[1].imgurl = val[4].imgurlA
      this.controlledmotion[1].Linecontrolled = val[6]
      this.controlledmotion[1].Performances = val[7]
      this.controlledmotion[1].actuator = val[8]
      this.controlledmotion[2].name = val[5].title
      this.controlledmotion[2].img = val[5].imgurl
      this.controlledmotion[2].imgurl = val[5].imgurlA
      this.controlledmotion[2].Linecontrolled = val[9]
      this.controlledmotion[2].Performances = val[10]
      this.controlledmotion[2].actuator = val[11]
    }
  }
};
</script>

<style lang="scss" scoped>
.Wirecontrolcore {
  width: 60%;
  margin: 0 auto;

  .roof {
    display: flex;
    justify-content: space-between;

    h4 {
      text-align: left;
      font-size: 17px;
      letter-spacing: 1px;
    }

    .liness {
      width: 28px;
      height: 2px;
      background: #333333;
    }

    img {
      width: 300px;
    }
  }

  .img_describe {
    width: 40%;
    // height: 187px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .Wirecontrolcore_setting {
    position: relative;
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;

    .following {
      height: 269px;
      display: flex;
      justify-content: center;
      align-items: center;

      .images {
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .image_change {
        width: 240px;
        transition: transform 0.2s ease-in-out;
      }

      .title {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 17px;
        color: #171717;
        letter-spacing: 1px;
      }
    }

    img {
      width: 200px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .mask {
      position: absolute;
      width: 33%;
      height: 100%;
      background-color: #00000070;
      display: inline-grid;
      z-index: 100;

      .Triangle {
        display: flex;
        justify-content: center;
      }

      .Triangles {
        top: 0 !important;

        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 20px solid #ffffff;
      }

      span {
        font-size: 17px;
        font-weight: bold;
        z-index: 999;
        color: #f1f1f1;
        letter-spacing: 1px;
        text-align: center;
      }

      .line {
        margin-top: 5px !important;
        width: 28px;
        height: 2px;
        background: #ffffff;
      }
    }
  }
}

.information {
  margin-top: 10px;
  color: #333333;
  text-align: left;

  span {
    font-size: 14px;
    font-weight: bold;
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 12px;
    opacity: 0.6;
    width: 90%;
    color: #333333be;
  }
}

.bottom_information {
  display: flex;

  // justify-content: space-between;
  text-align: justify;

  p {
    font-size: 12px;
    width: 250px;
    margin-right: 10px;
    text-align: left;
  }
}

.Wirecontrolcore_phone {
  display: none;

  .roof_phone {
    text-align: left;

    .small {
      font-size: 14px;

    }

    .liness {
      width: 28px;
      height: 2px;
      background: #333333;
    }

    .information_phone {
      margin-top: 10px;
      color: #333333;
      text-align: left;

      // line-height: 14px !important;
      .border {
        margin: 5px 0;
      }

      span {
        font-size: 14px;
        font-weight: bold;

      }

      p {
        padding: 0;
        margin: 0;
        font-size: 12px;
        opacity: 0.6;
        color: #333333be;
        // letter-spacing: 2px;
        line-height: 15px !important;
      }

      .bottom_information_phone {
        display: flex;

        // justify-content: space-between;
        text-align: justify;

        p {
          font-size: 12px;
          // margin-right: 10px;
          text-align: left;
          line-height: 14px;
        }
      }
    }

    .information_phone_img {
      display: flex;
      justify-content: center;

      // align-items: center;
      img {
        // width: 100px;
        height: 120px;
      }
    }

  }
}

// .Wirecontrolcore_phone .el-carousel .el-carousel__container div:nth-child(2).el-carousel__item .information_phone_img img{
//   width: 400px;
// }
</style>