<template>
    <div class="chassiss">
  <div class="chassis">
    <div>
    <ul v-for="(item, index) in Software" :key="index">
      <li>
        <img :src="item.img" class="software" v-if="item.img != ''" alt="">
        <div class="title">
        <span class="span">{{ item.name }}</span>
        <div class="title_p">
        <p  class="p">{{ item.center1 }}</p>
        <p class="p">{{ item.center2 }}</p>
    </div>
    </div>
        <img class="production" :src="item.img2" v-if="item.img2 != ''" alt="">
      </li>
    </ul>
</div>
  </div></div>
</template>

<script>
export default {
  props: ["Software"],
  data() {
    return {
      styleshow:false
    };
  },
  methods:{
 
  }
};
</script>

<style lang="scss" scoped>
.chassiss{
    width: 80%;
    margin: 0 auto;
}
.chassis{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chassis ul {
  list-style-type: none;
  margin: 0 ;
  padding: 0;
  
  .software{
    width: 450px;
  
  }
  .production{
    width: 450px;
  }
  li{
    display: flex;
    align-items: center;
    margin-top:34px ;
    .title:nth-child(1){
        // margin:0  50px;
        margin-left:25px ;
        margin-right:105px ;
    } .title:nth-child(2){
        // margin:0  50px;
        margin-left:105px ;
        margin-right:25px ;
    }
    .span{
        color: #1C1C1C;
        font-weight: bold;
        font-size: 32px;
        border-left: 8px solid #333333;
        padding-left: 10px;
        line-height: 9px;
        letter-spacing: 3px;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .title_p{
        margin-top: 16px;
        padding-left: 13px;
    }
    .p{
        color: #171717;
        font-size: 14px;
        margin: 5px;
        opacity: 0.7;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
  }
}

</style>