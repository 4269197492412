import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Select,Carousel,CarouselItem,Message } from 'element-ui';
import 'animate.css';
import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.use(VueAxios, axios)
Vue.use(Button).use(Select).use(Carousel).use(CarouselItem)
Vue.config.productionTip = false
// 响应式
import "@/assets/css/max1920.css"
import "@/assets/css/max1680.css"
import "@/assets/css/max1550.css"
import "@/assets/css/max1366.css"
import "@/assets/css/max1260.css"
import "@/assets/css/max1024.css"
import "@/assets/css/max992.css"
import "@/assets/css/max750.css"
import "@/assets/css/max479.css"

Vue.prototype.$message = Message;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
