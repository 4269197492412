import Vue from 'vue'
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import qs from 'qs'
// import date from './date.js'
// import moment from 'moment';
// moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 

// let url = "http://www.width168.com/api/index.php";
//  let token = ''
// 响应时间
// axios.defaults.timeout = 5 * 1000
// 配置cookie
// axios.defaults.withCredentials = true
// 配置cookie
// axios.defaults.headers.common['Authorization'] = token
// 配置请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.post['Content-Type'] = 'application/json'
//可以跨域

axios.defaults.withCredentials = false;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// 静态资源
Vue.prototype.$static = ''
// 配置接口地址
axios.defaults.baseURL = 'http://www.acmotion.cn/public/index.php/'
/* let loadingInstance */
// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(config => {
	//我觉得正常逻辑应该要token
	// if (config.method === 'post') {
	//   config.data = qs.stringify(config.data)
	// }
	//  let useinfo = window.sessionStorage.getItem('_evgetinfo1')
	// if (useinfo) {
	//   token = boost.Boost(useinfo)
	// }
	// let token = sessionStorage.getItem('token');
	let sign = '87d3bff5587890dba69e12eee1f33644'
	// let requesttime = moment(new Date()).format("YYYYMMDDHHmmss")
	let requesttime = '1658373524'
	// config.headers.common['token'] = token;
	// config.headers.common['sign'] = sign;
	// config.headers.common['requesttime'] = requesttime;
	//请求头转换为表单形式
	config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
	config.transformRequest = [function (data) {
		// 在请求之前对data传参进行格式转换
		data = qs.stringify(data)
		return data
	}]
	return config
}, err => {
	// Message.error('请求错误!');
	return Promise.reject(err)
})
// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(res => {
	return res


}, err => {
	Message.error('请求失败, 请稍后再试!');
	return Promise.reject(err)
})


export const HttpService = {
	// post请求
	post(url, params) {
		return new Promise((resolve, reject) => {
			axios
				.post(url, params)
				.then(res => {
					resolve(res.data)
				}, err => {
					reject(err.data)
				})
				.catch(err => {
					reject(err.data)
				})
		})
	},
	// get请求
	get(url, params) {
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					params: params
				})
				.then(res => {
					resolve(res)
				}).catch(err => {
					reject(err.data)
				})
		})
	},
	// put请求
	put(url, params) {
		return new Promise((resolve, reject) => {
			axios
				.put(url, params)
				.then(res => {
					resolve(res.data)
				}, err => {
					reject(err.data)
				})
				.catch(err => {
					reject(err.data)
				})
		})
	},

	// delete请求
	cut(url, params) {
		return new Promise((resolve, reject) => {
			axios
				.delete(url, params)
				.then(res => {
					resolve(res.data)
				}, err => {
					reject(err.data)
				})
				.catch(err => {
					reject(err.data)
				})
		})
	}
}

export const HttpPlugin = {
	install() {
		Vue.prototype.$http = HttpService
	}
}

