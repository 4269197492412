<template>
  <div class="Carousel">
    <div class="Carousel_list">
      <div class="Carousel_details" v-for="(items, index) in list" :key="index" @mouseenter="listItem(index)"
        @click="jump(index)">
        <!-- <img :src="items.img" alt=""   v-show="index != listItemId"/> -->
        <transition name="fade">
          <div class="cover" v-show="index != listItemId">
            <div class="cover_span">
              <span>{{ items.title }}</span>
              <div class="line"></div>
            </div>
            <div class="cover_img">
              <img :src="items.imgurl" class="cover_beij" alt="" />


            </div>

          </div>
        </transition>
        <div class="controlled_details" v-if="index == listItemId">
          <div class="details">
            <div class="details_span">
              <div v-if="items.title == 'AcMotion智能底盘-Bunny'" class="left">
                <span class="Swan">{{ items.title }}</span>
                <div class="lines"></div>
                <div class="left_si">
                  <p>{{ items.content1 }}</p>
                  <p>{{ items.content2 }}</p>
                  <p>{{ items.content3 }}</p>
                </div>
              </div>

              <div v-if="items.title == 'AcMotion智能底盘-Swan'" class="raehti">
                <div>
                  <span class="Swan">{{ items.title }}</span>
                  <div class="raehtis">
                    <div class="lines"></div>
                  </div>
                  <div class="raehtiss">
                    <p>{{ items.content1 }}</p>
                    <p>{{ items.content2 }}</p>
                    <p>{{ items.content3 }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div :class="items.title == 'AcMotion智能底盘-Swan' ? 'details_span_bottomnew' : 'details_span_bottom'">
              <div :class="items.title == 'AcMotion智能底盘-Swan' ? 'bottom_information_new' : 'bottom_information'">
                <div class="informations">
                  <div> {{ items.content4 }}</div>
                  <div> {{ items.content6 }}</div>
                  <div>{{ items.content8 }}</div>
                </div>
                <div class="informations">
                  <div> {{ items.content5 }}</div>
                  <div>
                    {{ items.content7 }}
                    <!-- <span style="margin-left: 10px" class="spans">{{
                      items.content10
                    }}</span> -->
                  </div>
                  <div> {{ items.content9 }}</div>
                </div>
              </div>
            </div>
            <transition name="slide-fade">
              <img :src="items.imgurlA" v-if="index == listItemId" alt="" />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="Carousel_list_iphne" @touchstart="start($event)" @touchmove="move($event)">
      <el-carousel ref="nop" arrow="never" :interval="8000" :autoplay="autoplay" height="400px" indicator-position="none">
        <div>
          <el-carousel-item v-for="(item, indexs) in list" :key="indexs">

            <div class="iphne_cover">
              <div class="Swanios">
                <span class="Swan_iphone">{{ item.title }}</span>
                <div class="raehtis">
                  <div class="lines"></div>
                </div>
              </div>
              <div class="cover_span">

                <div class="informations">
                  <div> {{ item.content4 }}</div>
                  <div> {{ item.content6 }}</div>
                  <div>{{ item.content8 }}</div>
                </div>
                <div class="informations">
                  <div> {{ item.content5 }}</div>
                  <div> {{ item.content7 }}</div>
                  <div>{{ item.content9 }}</div>
                </div>
              </div>
              <img :src="item.mobileimg" class="iphne_coverimg" alt="" />
            </div>
          </el-carousel-item>
        </div>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { OrderService } from "../api/home";
export default {
  data() {
    return {
      list: [],
      listItemId: 0,
      autoplay: true,
      startX: '',
      startY: '',
      moveY: '',
      moveX: '',
      startTime: '',
    };
  },
  mounted() {
    this.getchassis_list()
  },
  methods: {
    listItem(e) {
    },
    jump(e) {
      this.listItemId = e;
    },
    stopAuto() {
      this.autoplay = false
    },
    startAuto() {
      this.autoplay = true
    },
    start(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    move(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      var nowtime = new Date().getTime();
      if (this.startTime == undefined || nowtime > this.startTime) {
        if (this.startX - this.moveX < -50 && this.moveX) { // 右滑触发
          this.prev();
          return false;
        } else if (this.startX - this.moveX > 50 && this.moveX) { //左滑触发
          this.next();
          return false;
        }
      }
    },
    prev() {
      this.$refs.nop.prev();
      this.startTime = new Date().getTime() + 500;

    },
    next() {
      this.$refs.nop.next();
      this.startTime = new Date().getTime() + 500;

    },
    getchassis_list() {
      OrderService.getchassis().then((res) => {
        var data2 = res.data[0].below
        data2.forEach((element, index) => {
          if (index <= 1) {
            this.list.push(element)
          }
        });
        this.$set(this.list[0], 'mobileimg', data2[2].imgurl)
        this.$set(this.list[1], 'mobileimg', data2[3].imgurl)

      })
    }
  },
};
</script>

<style lang="scss" scoped>
.Carousel {
  background-color: #3333332c;
  width: 100%;
  // opacity: 0.1;
}

.Carousel_list {
  width: 60%;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  .cover {
    position: relative;
    background-size: 100%;

    display: flex;
    justify-content: center;

    .cover_span {
      position: absolute;
      bottom: 0;
      margin: 10px;
      z-index: 6;
    }

    .cover_img {
      // width: 283px;
      height: 571px;
      background-color: #00000076;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      transition: all 1s ease-out 0s;
      z-index: 5;
      cursor: pointer;

      .cover_beij {
        width: 300px;
        height: 210px;
        z-index: 3;
        transition: all 1s ease-out 0s;


      }
    }

    .cover_img:hover .cover_beij {
      // background-color: aqua;
      transform: scale(1.3);

    }

    // .cover_img:hover{
    //   width: 313px;
    //   height: 571px;
    //   // transition: all 1s ease-out;
    // }
    span {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      color: #fff;
      font-size: 17px;
      font-weight: bold;
    }

    .line {
      margin-top: 5px !important;
      margin: 0 auto;
      width: 35px;
      height: 2px;
      background: #ffffff;
    }
  }

  .controlled_details {
    width: 80%;
    z-index: 99;
    overflow: hidden;
    animation-name: drop-menu;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
    animation-timing-function: initial;

    @keyframes drop-menu {
      from {
        width: 0;
      }

      to {
        width: 100%;
      }
    }

    .details {
      background-size: 100%;
      width: 100%;
      height: 571px;
      transform-origin: left;
      transition: transform 0.2s ease-in-out;
      text-align: left;
      position: relative;

      p {
        margin: 0;
        padding: 0;
      }

      .details_span {
        position: absolute;

        width: 100%;
        display: flex;

        .left {
          padding: 20px 10px 10px 10px;

          .Swan {
            font-weight: bold;
            font-size: 18px;
            color: #333;
          }
        }

        .left_si {
          margin-top: 10px;

          p {
            font-size: 14px;
            font-family: PingFang SC;
            line-height: 18px;
            letter-spacing: 1.5px;
          }
        }

        .raehti {
          display: flex;
          justify-content: flex-end;
          text-align: right;
          padding: 10px;
          width: 100%;

          .Swan {
            font-weight: bold;
            font-size: 18px;
            color: #333;
          }
        }

        .raehtis {
          display: flex;
          justify-content: flex-end;
          text-align: right;


        }

        .raehtiss {
          margin-top: 10px;
          text-align: left;

          p {
            font-size: 14px;
            font-family: PingFang SC;
            line-height: 18px;
            letter-spacing: 1.5px;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      span {
        font-size: 16px;
        color: #333;
      }

      p {
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 1px;
      }

      .details_span_bottom {
        position: absolute;
        background-color: rgba(51, 51, 51, 0.425);
        width: 100%;
        bottom: 0;
        display: flex;
        align-items: flex-end;

        .divs {
          padding: 20px;

          span {
            color: #ffffff;
            font-size: 9px;
          }
        }
      }

      .details_span_bottomnew {
        position: absolute;
        background-color: rgba(51, 51, 51, 0.425);
        width: 100%;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .divs {
          padding: 20px;

          span {
            color: #ffffff;
            font-size: 9px;
          }
        }
      }

      .lines {
        width: 35px;
        height: 2px;
        background: #333;
        margin-top: 5px;
      }
    }
  }
}

.bottom_information {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: justify;
  padding: 10px;
  width: 80%;

  .informations {
    color: #FFFFFF;
    font-size: 9px;
    // width: 270px;
    margin-right: 10px;
    text-align: left;
    width: 50%;

  }

  .spans {
    color: #FFFFFF !important;
    font-size: 9px !important;
    // width: 270px;
    margin-right: 10px;
    text-align: left;
  }
}

.bottom_information_new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: justify;
  padding: 10px;
  width: 55%;

  .informations {
    color: #FFFFFF;
    font-size: 9px;
    // width: 270px;
    margin-right: 10px;
    text-align: left;
    // width: 50%;

  }

  .spans {
    color: #FFFFFF !important;
    font-size: 9px !important;
    // width: 270px;
    margin-right: 10px;
    text-align: left;
  }
}

.Carousel_list_iphne {
  display: none;

  .iphne_cover {
    position: relative;
    background-size: 100%;

    .Swanios {
      position: absolute;
      top: 10px;
      .Swan_iphone {
        text-align: center;
        justify-content: center;
        display: flex;
        margin: 0 auto;
       
      }
      .raehtis {
        display: flex;
        justify-content: flex-end;
        text-align: right;

        .lines {
          width: 45px;
          height: 2px;
          background: #333;
          margin-top: 5px;
        }

      }
    }



  }

  .cover_span {
    padding: 10px;
    height: 58px;
    position: absolute;
    text-align: left;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.425);
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .informations {
   
    width: 50%;
    font-size: 9px;
    color: #fff;
  }

  .iphne_coverimg {
    width: 100%;
    height: 400px;
  }
}</style>