import {
    HttpService
} from './https'


export const OrderService = {

  gethome(action) {
    return HttpService.get('/text', {
      action
    })
  },
  gettabar(action) {
    return HttpService.get('/nav', {
      action
    })
  },
  getchassis(action) {
    return HttpService.get('/textes', {
      action
    })
  }
}
