<template>
  <div class="Navigation" :class="styleshow == true ? 'enterMenu' : ''">
    <div class="tabr">
      <div class="logo">
        <img src="../assets/image/logo.png" alt="" />
      </div>
      <div class="tabr_name">
        <ul
          v-for="(items, index) in navigations"
          :key="index"
          @click="jump(index)"
          @mouseover="overItem(items)"
          @mouseleave="removeActive($event)"
          class="tabrs"
        >
          <li>
            <span :class="{ atcre: items.id == isactivebox }">{{
              items.TypeName
            }}</span>
          </li>
        </ul>
      </div>
      <template v-if="styleshowA == false"> </template>
      <template v-if="styleshowA == true">
        <div
          class="NavigationMenu_YD_icon"
          @click="quxiao = !quxiao"
          :class="quxiao ? '' : 'open'"
        >
          <span
            class="l_1"
            :style="styleshow == true ? '' : 'background : #fff'"
          ></span>
          <span
            class="l_2"
            :style="styleshow == true ? '' : 'background : #fff'"
          ></span>
          <span
            class="l_3"
            :style="styleshow == true ? '' : 'background : #fff'"
          ></span>
        </div>
      </template>
      <template v-if="styleshowA == false">
        <div
          class="NavigationMenu_YD_icon"
          @click="quxiao = !quxiao"
          :class="quxiao ? '' : 'open'"
        >
          <span class="l_1"></span>
          <span class="l_2"></span>
          <span class="l_3"></span>
        </div>
      </template>
    
    </div>
    <div
        class="NavigationMenu_YD"
        v-show="quxiao == false"
        :style="quxiao == true ? '' : 'height: 100%'"
      >
        <div class="NavigationMenu_YD_box">
          <div class="drop-down-box">
            <ul>
              <li v-for="(items,indexs) in navigations" :key="indexs"    @click="jumps(indexs)">
                <div class="drop-down-img">

                
                <img :src="items.imgurl" alt="">
              <span>{{items.TypeName}}</span>
            </div>
              <div class="lines"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: ["navigations"],
  data() {
    return {
      isactivebox: 1,
      pageid: null,
      styleshow: false,
      quxiao: true,
      styleshowA: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.ficedActiveBtn);
  },
  methods: {
    overItem(items) {
      this.isactivebox = items.id;
    },
    removeActive() {
      this.isactivebox = 1;
    },
    jump(key) {
      this.pageid = key;
      this.$emit("ievent", this.pageid);
    },
    jumps(indexs){
      this.quxiao=!this.quxiao;
      this.pageid = indexs;
      this.$emit("ievent", this.pageid);
    },
    ficedActiveBtn() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollTop >= 520 ? (this.styleshow = true) : (this.styleshow = false);
    },
  },
};
</script>


<style scoped lang="scss">
.Navigation {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.186);
  display: flex;
  justify-content: center;
  z-index: 9999;
  .tabr {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;
      }
    }
    .tabr_name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      li {
        margin: 0 20px;
        // width: 106px;
        height: 80px;
        line-height: 80px;
        align-items: center;
        text-align: center;
        box-sizing: content-box;
        border-bottom: 4px solid transparent;
        // position: relative;
        font-size: 16px;
        cursor: pointer;
      }
      span {
        position: relative;
        font-size: 16px;
        display: block;
        height: 100%;
        padding: 0 0.3vw;
        border-radius: 15px;
        z-index: 99999;
      }

      span:hover {
        color: #fff;
      }
      span::before {
        content: "";
        position: absolute;
        height: 2px;
        background: #fff;
        width: 100%;
        transform: scale(0);
        transform-origin: left;
        transition: transform 0.2s ease-in-out;
        left: 0;
        bottom: -4px;
      }
    }
    .tabrs {
      font-size: 18px;
      span {
        height: 100%;
        color: #eaeaea;
        letter-spacing: 2px;
      }
    }
  }
}
.enterMenu {
  color: #000 !important;
  background-color: #000;
}
.atcre {
  color: #fff;
}
.atcre::before {
  transform: scale(1) !important;
}
.NavigationMenu_YD_icon {
  width: 44px;
  height: 41px;
  cursor: pointer;
  position: relative;
  display: none;
  margin-left: 10px;

  span {
    display: block;
    width: 24px;
    height: 2px;
    left: 10px;
    background-color: #fff;
    position: absolute;
    transition: all 0.25s linear;
  }

  span.l_1 {
    top: 10px;
  }

  span.l_2 {
    top: 18px;
  }

  span.l_3 {
    top: 26px;
  }
}
.NavigationMenu_YD_icon.open span.l_2 {
  opacity: 0;
}

.NavigationMenu_YD_icon.open span.l_1 {
  top: 20px !important;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.NavigationMenu_YD_icon.open span.l_3 {
  top: 20px !important;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.NavigationMenu_YD {
  position: fixed;
  top: 44px;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  z-index: 99;
  overflow-y: auto;
  animation-name: drop-menu;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @keyframes drop-menu {
    from {
      height: 0;
    }

    to {
      height: 100%;
    }
  }

  .NavigationMenu_YD_box {
    width: 100%;
    // background: #fff;
    background: linear-gradient(54deg, #75F8D2, #6096DB, #2AE3FC);
    padding-bottom: 50px;
    ul{
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
}
.drop-down-box{
  padding: 10px;

  img{
    width: 25px;
    margin-right: 10px;
  }
  li{
    margin: 10px;
    padding: 5px;
    color: #fff;
 
    // border: 1px solid  #7b7b7b;
    .drop-down-img{
      display: flex;
  justify-content: left;
  align-items: center;
    }

    .lines{
      width: 100%;
      margin-top: 5px;
      height: 2px;
      background: #ffff;
      //  border: 2px solid  #7b7b7b;
      border-radius :10px ;
    }
  }
}

</style>
